import React from 'react';
import { render } from 'react-dom';

const { webChatUrl } = window;
const WebChatComponentRoot = () => {
  const open = () => {
    window.open(`${webChatUrl}`, 'popup', 'width=400,height=500');
    return false;
  };
  return (
    <button className="web-chat-component-button" target="popup" onClick={open} />
  );
};

render(<WebChatComponentRoot />, document.getElementById('webChatButton'));
